<template>
  <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item active">
          <router-link :to="{ name: 'attendances' }">Attendances</router-link>
        </li>
        <li class="breadcrumb-item active">Add</li>
      </ol>
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row align-items-center">
        <div class="col-5">
          <h2 class="title2">Add Employee Attendance</h2>
        </div>
        <div class="col text-end">
          <router-link
            :to="{ name: 'attendances' }"
            class="btn btn-outline-primary"
            >Back</router-link
          >
        </div>
      </div>
      <div v-if="this.$store.state.loader">
        <loader
          object="#f74b3d"
          color1="#ffffff"
          color2="#17fd3d"
          size="5"
          speed="2"
          bg="#343a40"
          objectbg="#999793"
          opacity="80"
          disableScrolling="false"
          name="spinning"
        ></loader>
      </div>
      <form v-else @submit.prevent="onSubmit">
        <div class="row">
          <div class="col-lg-6 mb-3">
            <div class="card">
              <div class="card-header">
              </div>
              <div class="card-body">
                <div class="mb-3">
                  <label class="form-label">Employee</label>
                  <b-form-select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="$v.form.tempName.$model"
                    :validator="$v.form.tempName"
                    @change="setUserData"
                    :options="employees"
                  ></b-form-select>
                  <span
                    class="error "
                    v-if="$v.form.tempName.$error == true"
                    >Please select employee
                  </span>
                </div>
                <div class="mb-3">
                  <label class="form-label">Date</label>

                  <datepicker
                    typeable="true"
                    placeholder="Select Date"
                    class="form-control"
                    v-model="$v.form.date.$model"
                    :validator="$v.form.date"
                    :format="customFormatter"
                    :highlighted="highlighted"
                  ></datepicker>
                  <span
                    class="error "
                    v-if="$v.form.date.$error == true"
                    >Date is required</span
                  >
                </div>
                    <div class="mb-3">
                      <label class="form-label">Time in</label>
                      <div>
                      <vue-timepicker
                        format="hh:mm A"
                        v-model="$v.form.timein.$model"
                        :validator="$v.form.timein"
                        auto-scroll
                        @change="timeChangeHandler($event,'timein')"
                      ></vue-timepicker>
                      </div>
                      <p
                        class="error "
                        v-if="$v.form.timein.$error == true"
                        >Time in is required</p
                      >
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Time out</label>
                      <div>
                      <vue-timepicker
                        format="hh:mm A"
                        v-model="$v.form.timeout.$model"
                        :validator="$v.form.timeout"
                        auto-scroll
                        @change="timeChangeHandler($event,'timeout')"
                      ></vue-timepicker>
                      </div>
                      <p
                        class="error "
                        v-if="$v.form.timeout.$error == true"
                        >Time out is required</p
                      >
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Reason</label>
                    <div>
                      <textarea col="5"
                        v-model="$v.form.reason.$model"
                        :validator="$v.form.reason"
                      ></textarea>
                      </div>
                      <p
                        class="error "
                        v-if="$v.form.reason.$error == true"
                        >Reason is required</p
                      >
                    </div>
                <div class="text-right">
                  <button type="submit" class="btn btn-outline-primary space">
                    Save
                  </button>
                  <router-link
                    :to="{ name: 'attendances' }"
                    class="btn btn-outline-primary"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { required, sameAs } from "vuelidate/lib/validators";
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import moment from 'moment';
import axios from 'axios';
export default {
  components: { Datepicker, VueTimepicker },
  props: ["id"],
  data() {
    return {
      form: {
        name: null,
        tempName: null,
        date: null,
        timein: '10:00 AM',
        timeout: '07:00 PM',
        reason: null
      },
      highlighted: { dates: [ new Date("yyyy-MM-dd") ]},
      timeValue: '00:01:00'
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      tempName: {
        required,
      },
      date: {
        required,
      },
      timein: {
        required,
      },
      timeout: {
        required,
      },
      reason: {
        required
      }
    },
  },
  created() {
    this.$store.dispatch("switchLoader", true);
    this.$store.dispatch("loadEmployees");
  },
  mounted() {
    console.log("users ", this.employees);
  },
  computed: {
    employees() {
      return this.$store.state.employees.map((employee) => {
        return {
          value: employee.id,
          text: employee.lastname + ", " + employee.firstname,
        };
      });
    },
  },
  methods: {
    timeChangeHandler(event,time){
        if(time == 'timein'){
            this.form.timein = event.displayTime;
        }
        else{
            this.form.timeout = event.displayTime;
        }
        
    },
    setUserData() {
      console.log(this.form.tempName);
      let empDetail = this.$store.state.employees.filter((emp) => {
        return emp.id == this.form.tempName;
      })[0];
      this.form.email = empDetail.emailaddress;
      this.form.ref = this.form.tempName;
      this.$v.form.name.$model =
        empDetail.lastname + ", " + empDetail.firstname;
      console.log("name", this.$v.form.name);
    },
    customFormatter(date) {
      return moment(date).format('yyyy-MM-DD');
    },
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      axios
        .post("/attendance/add-entry", this.form)
        .catch(({ response }) => {
          this.flashMessage.error({
            message: response.data.error,
            time: 3000,
          });
        })
        .then(({ data }) => {
          if (data.success) {
            this.flashMessage.success({
              message: data.success,
              time: 3000,
            });
            this.$router.push({ name: "attendances" });
          }
        });
    },
    onChangeFileUpload() {
      this.form.image = this.$refs.file.files[0];
    },
  },
};
</script>